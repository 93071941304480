import { Constants, errorLogger } from '../../../../lib/ads/utils'
import { dispatchCustomEvent } from '../../../../lib/utils/events'
import { rapidReady } from '../../../../lib/utils/rapid'

const STATUS = {
  NOT_BLOCKED: '0',
  BLOCKED_NO_ABP: '1',
  BLOCKED_ABP: '2'
}

const ABK_STRING = {
  [STATUS.BLOCKED_ABP]: 'abp',
  [STATUS.BLOCKED_NO_ABP]: 'other',
  [STATUS.NOT_BLOCKED]: ''
}

const CLASS_ABP = 'isABP'
const ABD_SCRIPT = 'https://s.yimg.com/jk/aabd/prod/adblockerdetector.umd.js'
const ABD_RESOURCES_PATH = 'https://s.yimg.com/cv/apiv2/aolfp/abd-resources'

export default (() => {
  class AdBlocker {
    constructor({ selector }) {
      this.elem = document.querySelector(selector)
      if (!this.elem) {
        return
      }

      try {
        this.config = JSON.parse(this.elem.dataset.config)
      } catch (e) {
        throw new Error('Error Parsing Adblocker Config')
      }
      this.utils = window.NEXUtils || {}
      this.statusOnLoad = this.config.thamba
      this.status = this.statusOnLoad

      if (this.statusOnLoad === STATUS.BLOCKED_ABP) {
        document.documentElement.classList.add(CLASS_ABP)
      }

      setTimeout(this.init.bind(this), this.config.detectionTimeout || 300)
    }

    async init() {
      try {
        await this._loadAdBlockerLib()
        await this.detect()
      } catch (e) {
        if (!e) {
          this._logError('Error loading AdBlocker lib')
        } else {
          this._logError(e)
        }
      }
    }

    async detect() {
      this.abBlokerDetected = await this._isAdBlockerDetected()

      if (this.abBlokerDetected) {
        this.isABP = await this.isABPAdBlockerDetected()
      }

      if (this.abBlokerDetected && this.isABP) {
        this.status = STATUS.BLOCKED_ABP
      } else if (this.abBlokerDetected) {
        this.status = STATUS.BLOCKED_NO_ABP
      } else {
        this.status = STATUS.NOT_BLOCKED
      }

      const abk = ABK_STRING[this.status]

      // Only perform the following if the status has changed
      if (this.statusOnLoad !== this.status) {
        this._setCookie()

        if (this.isABP) {
          document.documentElement.classList.add(CLASS_ABP)
        } else {
          document.documentElement.classList.remove(CLASS_ABP)
        }
        rapidReady(rapid => {
          rapid.setRapidAttribute({ keys: { abk } })
          rapid.beaconEvent('daTrk', { pl1: this.status, abk })
        })
        dispatchCustomEvent('GAM:enableAdBlock', abk)
      } else {
        // Beacon to rapid the existing status
        rapidReady(rapid => {
          rapid.beaconEvent('daTrk', { pl1: this.status, abk })
        })
      }
    }

    _setCookie() {
      this.utils.setCookie(this.config.cookieName, this.status, this.config.cookieDuration)
    }

    async _isAdBlockerDetected() {
      const adBlockerLib = window.ABD
      if (!adBlockerLib) {
        throw new Error('AdBlocker lib not loaded')
      }
      return adBlockerLib.isAdBlockerEnabled(ABD_RESOURCES_PATH)
    }

    async isABPAdBlockerDetected() {
      const adBlockerLib = window.ABD
      if (!adBlockerLib) {
        throw new Error('AdBlocker lib not loaded')
      }
      return adBlockerLib.isAdblockPlus({ px: this.config.pxImg })
    }

    async _loadAdBlockerLib() {
      return this.utils.loadScript(ABD_SCRIPT)
    }

    _logError(e, level = Constants.ERROR_LEVEL.ERROR) {
      const error = typeof e === 'string' ? new Error(e) : e
      const logger = errorLogger()
      logger(`[AdBlocker ${level}] ${error?.message || 'Generic Error'}`, error)
    }
  }

  return AdBlocker
})()
